/* eslint-disable */
import { createRouter, createWebHistory } from "vue-router";
import { authInstance } from "./lib/adminInstance";
import { clientInstance } from "./lib/clientInstance";

import HomePage from "./pages/HomePage.vue";
import LoginPage from "./pages/admin/LoginPage.vue";
import AdminTvArchive from "./pages/admin/AdminTvArchive.vue";
import NotFoundPage from "./pages/NotFoundPage.vue";
import AdminDashboard from "./pages/admin/AdminDashboard.vue";
import AdminVodList from "./pages/admin/AdminVodList.vue";
import AdminUpload from "./pages/admin/AdminUpload.vue";

import SignUpPage from "./pages/client/SignUpPage.vue";
import UserSigninPage from "./pages/client/UserSigninPage.vue";
import PlanChoose from "./pages/client/PlanChoose.vue";
import ProfilePage from "./pages/client/ProfilePage.vue";
import PaymentPage from "./pages/client/PaymentPage.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "HomePage",
      component: HomePage,
      meta: { title: "Home Page" },
    },
    {
      path: "/login",
      name: "LoginPage",
      component: LoginPage,
      meta: { title: "Login Page" },
    },
    {
      path: "/admin/tv-archive",
      name: "TvArchive",
      component: AdminTvArchive,
      meta: { title: "ТВ архив", requiresAuth: true },
    },
    {
      path: "/admin/dashboard",
      name: "AdminDashboard",
      component: AdminDashboard,
      meta: { title: "Хянах самбар", requiresAuth: true },
    },
    {
      path: "/admin/upload",
      name: "AdminUpload",
      component: AdminUpload,
      meta: { title: "Бичлэг оруулах", requiresAuth: true },
    },
    {
      path: "/admin/vod-list",
      name: "AdminVodList",
      component: AdminVodList,
      meta: { title: "Бичлэгийн жагсаалт", requiresAuth: true },
    },
    {
      path: "/signup",
      name: "SignUpPage",
      component: SignUpPage,
      meta: { title: "MNB Aпп" },
    },
    {
      path: "/signin",
      name: "UserSigninPage",
      component: UserSigninPage,
      meta: { title: "MNB Aпп" },
    },
    {
      path: "/signup/planChoose",
      name: "PlanChoose",
      component: PlanChoose,
      meta: { title: "MNB Aпп", requireUserAuth: true },
    },
    {
      path: "/signup/payment",
      name: "PaymentPage",
      component: PaymentPage,
      meta: { title: "MNB Aпп", requireUserAuth: true },
    },
    {
      path: "/profile",
      name: "ProfilePage",
      component: ProfilePage,
      meta: { title: "MNB Aпп", requireUserAuth: true },
    },
    {
      path: "/:pathMatch(.*)*",
      name: "Page404",
      component: NotFoundPage,
      meta: { title: "Page Not Found" },
    },
  ],
});

const checkAuth = async () => {
  try {
    const response = await authInstance.get("/check");
    return response.data.authorized;
  } catch (error) {
    console.error("Error in checkAuth:", error);
    return false;
  }
};

const checkUserAuth = async () => {
  try {
    const response = await clientInstance.get("/user/check");

    return response.data.authorized;
  } catch (error) {
    console.error("Error in checkUserAuth:", error);
    return false;
  }
};

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const isAdmin = await checkAuth();
    if (!isAdmin) {
      return next("/login");
    }
    return next();
  }

  if (to.matched.some((record) => record.meta.requireUserAuth)) {
    const isUser = await checkUserAuth();
    if (!isUser) {
      console.log("r");

      return next("/signup");
    }
    return next();
  }

  if (to.path === "/login") {
    const isAdmin = await checkAuth();
    if (isAdmin) {
      return next("/admin/dashboard");
    }
    return next();
  }

  if (to.path === "/signup") {
    const isUser = await checkUserAuth();
    if (isUser) {
      return next("/profile");
    }
    return next();
  }

  next();
});

export default router;
