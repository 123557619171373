<template>
  <client-container>
    <div class="flex pt-20 justify-center py-4 px-6">
      <div class="max-w-md w-full text-black">
        <h1 class="text-2xl font-bold mb-8 text-center">Бүртгүүлэх</h1>

        <div class="relative mb-4">
          <input
            type="email"
            id="email"
            v-model="email"
            placeholder=" "
            class="block rounded-t-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 bg-gray-50 dark:bg-gray-700 border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-[#F60002] focus:outline-none focus:ring-0 focus:border-[#F60002] peer"
          />
          <label
            for="email"
            class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-[#F60002] peer-focus:dark:text-[#F60002] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4"
          >
            И-Мэйл
          </label>
          <div v-if="fieldErrors.email" class="text-[#F60002] text-sm mt-1">
            {{ fieldErrors.email }}
          </div>
        </div>

        <div class="relative mb-4">
          <input
            type="password"
            id="password"
            v-model="password"
            placeholder=" "
            class="block rounded-t-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 bg-gray-50 dark:bg-gray-700 border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-[#F60002] focus:outline-none focus:ring-0 focus:border-[#F60002] peer"
          />
          <label
            for="password"
            class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-[#F60002] peer-focus:dark:text-[#F60002] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4"
          >
            Нууц үг
          </label>
          <div v-if="fieldErrors.password" class="text-[#F60002] text-sm mt-1">
            {{ fieldErrors.password }}
          </div>
        </div>

        <div class="relative mb-8">
          <input
            type="password"
            id="passwordConfirm"
            v-model="passwordConfirm"
            placeholder=" "
            class="block rounded-t-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 bg-gray-50 dark:bg-gray-700 border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-[#F60002] focus:outline-none focus:ring-0 focus:border-[#F60002] peer"
          />
          <label
            for="passwordConfirm"
            class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-[#F60002] peer-focus:dark:text-[#F60002] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4"
          >
            Нууц үг давтах
          </label>

          <div v-if="fieldErrors.passwordConfirm" class="text-[#F60002] text-sm mt-1">
            {{ fieldErrors.passwordConfirm }}
          </div>
        </div>

        <button @click="handleSignup" class="w-full bg-[#F60002] text-white font-bold py-3 rounded hover:bg-red-600 transition-colors text-lg">Бүртгүүлэх</button>

        <div class="text-center mt-6 text-gray-700">
          <span>Бүртгэлтэй хаяг байгаа юу?</span>
          <button @click="handleLogin" class="ml-1 underline font-semibold hover:text-black">Нэвтрэх</button>
        </div>
      </div>
    </div>
  </client-container>
</template>
<script setup>
/* eslint-disable */
import { reactive, ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const store = useStore();
const router = useRouter();

const isAuthenticated = computed(() => store.state.client.isAuthenticated);

// Form fields
const email = ref("");
const password = ref("");
const passwordConfirm = ref("");

// Using a reactive object for field errors
const fieldErrors = reactive({});

// Helper to validate email format
function validateEmail(emailInput) {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(String(emailInput).toLowerCase());
}

async function handleSignup() {
  // Reset errors
  fieldErrors.email = null;
  fieldErrors.password = null;
  fieldErrors.passwordConfirm = null;

  const errors = {};

  // Email validation
  if (!email.value.trim()) {
    errors.email = "И-Мэйл оруулна уу.";
  } else if (!validateEmail(email.value)) {
    errors.email = "И-Мэйл буруу байна.";
  }

  // Password validation
  if (!password.value.trim()) {
    errors.password = "Нууц үг оруулна уу.";
  } else if (password.value.length < 8) {
    errors.password = "Нууц үг хамгийн багадаа 8 тэмдэгттэй байх ёстой.";
  }

  // Confirm password
  if (!passwordConfirm.value.trim()) {
    errors.passwordConfirm = "Нууц үгээ давтаж оруулна уу.";
  } else if (passwordConfirm.value !== password.value) {
    errors.passwordConfirm = "Нууц үг таарахгүй байна.";
  }

  // If any errors exist, display them and stop
  if (Object.keys(errors).length > 0) {
    fieldErrors.email = errors.email;
    fieldErrors.password = errors.password;
    fieldErrors.passwordConfirm = errors.passwordConfirm;
    return;
  }

  try {
    const response = await store.dispatch("client/signup", {
      email: email.value,
      password: password.value,
    });

    if (response) {
      router.push("/signup/planChoose");
    }
  } catch (error) {
    // Here you could display server errors, etc.
    console.error("Signup failed:", error?.response?.data || error.message);
  }
}

function handleLogin() {
  router.push("/signin");
}
</script>

<style scoped>
/* Tailwind handles most styling. */
</style>
