<template>
  <div class="space-y-4">
    <!-- Category dropdown -->
    <div>
      <label class="block text-gray-700 mb-1" for="category">Төрөл</label>
      <select
        id="category"
        name="categoryId"
        @change="handleVideoForm"
        :value="videoForm.categoryId"
        required
        class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
      >
        <option selected disabled value="">Сонгох</option>
        <option v-for="category in categories" :key="category.id" :value="category.category_id">
          {{ category.name }}
        </option>
      </select>
    </div>

    <!-- Title -->
    <div>
      <label class="block text-gray-700 mb-1" for="title">Бичлэг нэр</label>
      <input
        id="title"
        name="title"
        :value="videoForm.title"
        @input="handleVideoForm"
        type="text"
        required
        class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        placeholder="Бичлэг нэр оруулна уу"
      />
    </div>

    <!-- Description -->
    <div>
      <label class="block text-gray-700 mb-1" for="description">Тайлбар</label>
      <textarea
        id="description"
        name="description"
        :value="videoForm.description"
        @input="handleVideoForm"
        required
        class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        rows="4"
        placeholder="Бичлэг тайлбар оруулна уу"
      ></textarea>
    </div>

    <!-- Price -->
    <div>
      <label class="block text-gray-700 mb-1" for="price">Үнэ (₮)</label>
      <input
        id="price"
        name="price"
        :value="videoForm.price"
        @input="handleVideoForm"
        type="number"
        min="0"
        required
        class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        placeholder="Бичлэг үнэ оруулна уу"
      />
    </div>

    <!-- Image file -->
    <div>
      <label class="block text-gray-700 mb-1" for="image">Бичлэг зураг</label>
      <input id="image" name="image" @change="handleFileChange" ref="fileImageRef" type="file" class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" />
    </div>

    <!-- Video file input / TUS uploader -->
    <div>
      <label class="block text-gray-700 mb-1" for="videoFile">Бичлэг</label>
      <input
        id="videoFile"
        name="videoFile"
        type="file"
        accept="video/*"
        @change="onVideoSelected"
        ref="fileVideoRef"
        class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
    </div>

    <!-- TUS Upload Progress -->
    <div v-if="uploadProgress > 0 && uploadProgress < 100" class="mt-4">
      <p class="mb-1">
        Бичлэгийг оруулж байна:
        <strong>{{ uploadProgress.toFixed(2) }}%</strong>
      </p>
      <p class="mb-2">
        Үлдсэн хугацаа:
        <strong>{{ formattedTimeRemaining }}</strong>
      </p>
      <!-- Tailwind-styled progress bar -->
      <div class="w-full bg-gray-200 rounded-full h-4">
        <div class="bg-blue-600 h-4 rounded-full transition-all duration-300" :style="{ width: uploadProgress + '%' }"></div>
      </div>
    </div>
    <div v-else-if="uploadProgress >= 100" class="mt-4">
      <p class="text-green-600 font-semibold">Бичлэг амжилттай илгээгдлээ!</p>
    </div>

    <!-- Conversion Progress (second bar) -->
    <div v-if="conversionProgress > 0 && conversionProgress < 100" class="mt-4">
      <p class="mb-1">
        720p болгон хөрвүүлж байна:
        <strong>{{ conversionProgress.toFixed(2) }}%</strong>
      </p>
      <div class="w-full bg-gray-200 rounded-full h-4">
        <div class="bg-green-600 h-4 rounded-full transition-all duration-300" :style="{ width: conversionProgress + '%' }"></div>
      </div>
    </div>
    <div v-else-if="conversionProgress >= 100" class="mt-4">
      <p class="text-green-600 font-semibold">720p болгон хөрвүүлж дууслаа!</p>
    </div>

    <!-- Submit/Upload button -->
    <div class="mt-4">
      <button @click="startUpload" :disabled="isLoading" class="w-[250px] bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-lg transition duration-200 disabled:opacity-50">
        {{ isLoading ? "Бичлэг нэмэж байна..." : "Бичлэг нэмэх" }}
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";

import * as tus from "tus-js-client";
import { useStore } from "vuex";
import { adminInstance } from "@/lib/adminInstance";
// Our store
const store = useStore();

/**
 * Refs & Reactive State
 */
const selectedVideo = ref(null);
const uploadProgress = ref(0);
const timeRemaining = ref(0);
const startTime = ref(null);
const fileImageRef = ref(null);
const fileVideoRef = ref(null);
const isUploaded = ref(false);

// For the second progress bar (FFmpeg conversion)
const conversionProgress = ref(0);

/**
 * Computed from Vuex store
 */
const isLoading = computed(() => store.state.vod.isLoading);
const categories = computed(() => store.state.vod.categories);
const videoForm = computed(() => store.state.vod.videoForm);

/**
 * Format the remaining time in seconds into a more human-friendly string.
 */
const formattedTimeRemaining = computed(() => {
  let t = timeRemaining.value;
  if (t < 0) t = 0;

  if (t < 60) {
    return `${t.toFixed(0)}сек`;
  } else if (t < 3600) {
    const minutes = Math.floor(t / 60);
    const seconds = Math.floor(t % 60);
    return `${minutes}мин ${seconds}сек`;
  } else if (t < 86400) {
    const hours = Math.floor(t / 3600);
    const remainder = t % 3600;
    const minutes = Math.floor(remainder / 60);
    return `${hours}цаг ${minutes}мин`;
  } else {
    const days = Math.floor(t / 86400);
    const remainder = t % 86400;
    const hours = Math.floor(remainder / 3600);
    return `${days}өдөр ${hours}цаг`;
  }
});

/**
 * Update store when inputs change
 */
const handleVideoForm = (e) => {
  store.commit("vod/setVideoForm", {
    key: e.target.name,
    value: e.target.value,
  });
};

/**
 * Handle image input or TUS final URL
 */
const handleFileChange = (eOrVideoUrl) => {
  let key = null;
  let value = null;

  // If it's a string, it's the final TUS URL
  if (typeof eOrVideoUrl === "string") {
    key = "video";
    value = eOrVideoUrl;
  } else {
    // It's the image file
    key = "image";
    const file = eOrVideoUrl.target.files[0] || null;
    value = file;
  }

  store.commit("vod/setVideoForm", { key, value });
};

/**
 * Submit the course/video to the server:
 *   This calls createVideo (which renames, etc.)
 */
const submitCourse = () => {
  store.dispatch("vod/createVideo");

  // Reset inputs
  if (fileImageRef.value) {
    fileImageRef.value.value = "";
  }
  if (fileVideoRef.value) {
    fileVideoRef.value.value = "";
  }

  selectedVideo.value = null;
  uploadProgress.value = 0;
  timeRemaining.value = 0;
  isUploaded.value = false;
};

/**
 * User selects a video file
 */
function onVideoSelected(e) {
  selectedVideo.value = e.target.files[0] || null;
  uploadProgress.value = 0;
  timeRemaining.value = 0;
  isUploaded.value = false;
}

/**
 * Poll the server for conversion progress
 */
let pollInterval = null;
function pollConversionProgress(uploadId) {
  // Clear any old intervals
  if (pollInterval) {
    clearInterval(pollInterval);
  }

  pollInterval = setInterval(async () => {
    try {
      const res = await adminInstance.get(`/upload/convert/status/${uploadId}`);
      const { progress, status } = res.data;
      conversionProgress.value = progress;

      // If done or error => stop polling
      if (status === "done" || status === "error") {
        clearInterval(pollInterval);
        pollInterval = null;
      }
    } catch (err) {
      console.error("Polling error:", err);
      clearInterval(pollInterval);
      pollInterval = null;
    }
  }, 2000); // poll every 2s
}

/**
 * Start the TUS upload
 */
function startUpload() {
  if (isUploaded.value) {
    console.log("Video already uploaded. Skipping...");
    return;
  }
  if (!selectedVideo.value) {
    alert("Please select a video file first");
    return;
  }

  startTime.value = Date.now();

  const upload = new tus.Upload(selectedVideo.value, {
    endpoint: "http://localhost:3002/tus-video/video",
    retryDelays: [0, 3000, 5000, 10000, 20000],
    metadata: {
      filename: selectedVideo.value.name,
      filetype: selectedVideo.value.type,
    },
    onError: (error) => {
      console.error("Upload failed:", error);
      // Optionally show error in your store
    },
    onProgress: (bytesUploaded, bytesTotal) => {
      const percentage = (bytesUploaded / bytesTotal) * 100;
      uploadProgress.value = percentage;

      const currentTime = Date.now();
      const timeElapsed = (currentTime - startTime.value) / 1000;
      const uploadSpeed = bytesUploaded / timeElapsed;
      const bytesRemaining = bytesTotal - bytesUploaded;
      const estimatedTime = uploadSpeed ? bytesRemaining / uploadSpeed : 0;
      timeRemaining.value = estimatedTime;
    },
    onSuccess: async () => {
      uploadProgress.value = 100;
      timeRemaining.value = 0;
      isUploaded.value = true;

      // 1) Store the TUS URL in our videoForm
      handleFileChange(upload.url);

      // 2) Immediately submit the course: calls createVideo, which renames & stores
      //    in DB (but doesn't do the FFmpeg in this example)
      //    or if createVideo does do FFmpeg inline, it won't need polling
      submitCourse();

      // 3) If your code has a *separate* conversion route to do the 720p,
      //    call it here:
      const uploadId = upload.url.split("/").pop();
      await adminInstance.post("/upload/conversion", { uploadId });

      // 4) Start polling for conversion progress
      pollConversionProgress(uploadId);
    },
  });

  // Check for previous partial upload
  upload.findPreviousUploads().then((previousUploads) => {
    if (previousUploads.length) {
      upload.resumeFromPreviousUpload(previousUploads[0]);
    }
    upload.start();
  });
}
</script>
