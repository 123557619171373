import { createStore } from "vuex";
import admin from "./admin";
import vod from "./vod";
import client from "./client";
export default createStore({
  namespaced: true,
  modules: {
    admin,
    vod,
    client,
  },
});
