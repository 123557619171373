/* eslint-disable */
import { clientInstance } from "@/lib/clientInstance";

export default {
  namespaced: true,

  state: () => ({
    isAuthenticated: false,
    planOptions: [
      { months: 1, price: 50, label: "Багц 1" },
      { months: 3, price: 150, label: "Багц 2" },
      { months: 6, price: 300, label: "Багц 3" },
    ],

    isLoading: false,
    selectedPlan: null,
    paymentStatus: null,
    paymentOptions: [],
    invoiceNumber: null,

    profile: null,
    subscription: null,
  }),

  mutations: {
    SET_LOADING(state, isLoading) {
      state.isLoading = isLoading;
    },
    SET_AUTH(state, payload) {
      state.isAuthenticated = payload;
    },

    SET_PLAN(state, plan) {
      state.selectedPlan = plan;
    },

    SET_PAYMENT_STATUS(state, status) {
      state.paymentStatus = status;
    },
    SET_PAYMENT_OPTIONS(state, options) {
      state.paymentOptions = options;
    },
    SET_INVOICE_NUMBER(state, number) {
      console.log("invoiceNumber commit", number);
      state.invoiceNumber = number;
    },

    SET_SUBSCRIPTION(state, subscription) {
      state.subscription = subscription;
    },

    SET_PROFILE(state, profile) {
      console.log("profile", profile);
      state.profile = profile;
    },
  },

  actions: {
    async login({ commit }, { email, password }) {
      const response = await clientInstance.post("/user/login", { email, password });
      if (response.data.success) {
        commit("SET_AUTH", true);
      }
      return response.data.success;
    },

    async signup({ commit }, { email, password }) {
      const response = await clientInstance.post("/user/register", { email, password });
      if (response.data.success) {
        commit("SET_AUTH", true);
      }
      return response.data.success;
    },

    async deleteAccount() {
      const response = await clientInstance.post("/user/delete");
      if (response.data.success) {
        // commit("SET_AUTH", true);
        console.log("deleted");
      }
      return response.data.success;
    },

    async logout() {
      try {
        const response = await clientInstance.post("/user/logout");
        if (response.status === 200) {
          window.location.href = "/signup";
        }
      } catch (error) {
        console.error(error);
      }
    },

    choosePlan({ commit }, plan) {
      commit("SET_PLAN", plan);
    },

    async payment({ state, commit }) {
      try {
        commit("SET_PAYMENT_STATUS", "pending");

        const response = await clientInstance.post("/user/payment", {
          plan: state.selectedPlan.months,
          price: state.selectedPlan.price,
        });

        if (response.status === 200) {
          const { paymentOptions, invoiceNumber } = response.data;

          commit("SET_PAYMENT_OPTIONS", paymentOptions);

          commit("SET_INVOICE_NUMBER", invoiceNumber);
        }
      } catch (error) {
        console.error("Payment error:", error);
        commit("SET_PAYMENT_STATUS", "failed");
      }
    },

    async checkPaymentStatus({ state, commit }) {
      try {
        const invoiceNumber = state.invoiceNumber;
        if (!invoiceNumber) return;
        const response = await clientInstance.get(`/user/check-payment/${invoiceNumber}`);
        const { status } = response.data;
        commit("SET_PAYMENT_STATUS", status);
      } catch (error) {
        console.error(error);
      }
    },

    async checkUserSubscription({ commit }) {
      try {
        commit("SET_LOADING", true);
        const response = await clientInstance.get("/user/subscription");
        commit("SET_SUBSCRIPTION", response.data.subscription);
      } finally {
        commit("SET_LOADING", false);
      }
    },
    async fetchProfileInfo({ commit }) {
      try {
        commit("SET_LOADING", true);
        const response = await clientInstance.get("/user/profile");
        commit("SET_PROFILE", response.data.profile);
      } finally {
        commit("SET_LOADING", false);
      }
    },
  },
};
