<template>
  <div>
    <form @submit.prevent="submitEpisode" class="space-y-4">
      <!-- 1) Series Selection -->
      <div>
        <label class="block text-gray-700 mb-1" for="seriesId">Цуврал сонгох</label>
        <select id="seriesId" name="seriesId" @change="handleSeriesEpisodeForm" required class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500">
          <option selected disabled value="">Сонгох</option>
          <option v-for="series in seriesList" :key="series.id" :value="series.id">
            {{ series.title }}
          </option>
        </select>
      </div>

      <!-- 2) Season Selection -->
      <div v-if="seasons.length > 0">
        <label class="block text-gray-700 mb-1" for="seasonId">Бүлэг сонгох</label>
        <select id="seasonId" name="seasonId" @change="handleSeriesEpisodeForm" required class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500">
          <option selected disabled value="">Сонгох</option>
          <option v-for="season in seasons" :key="season.id" :value="season.id">{{ season.season_number }}-р бүлэг</option>
        </select>
      </div>

      <!-- 3) Episode Number -->
      <div>
        <label class="block text-gray-700 mb-1" for="episodeNumber">Ангийн дугаар</label>
        <input
          id="episodeNumber"
          name="episodeNumber"
          :value="seriesEpisodeForm.episodeNumber"
          @input="handleSeriesEpisodeForm"
          type="number"
          required
          class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Анги дугаар оруулна уу"
        />
      </div>

      <!-- 4) Episode Title -->
      <div>
        <label class="block text-gray-700 mb-1" for="title">Ангийн нэр</label>
        <input
          id="title"
          name="title"
          :value="seriesEpisodeForm.title"
          @input="handleSeriesEpisodeForm"
          type="text"
          required
          class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Анги нэр оруулна уу"
        />
      </div>

      <!-- 5) Description -->
      <div>
        <label class="block text-gray-700 mb-1" for="description">Тайлбар</label>
        <textarea
          id="description"
          name="description"
          :value="seriesEpisodeForm.description"
          @input="handleSeriesEpisodeForm"
          required
          class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          rows="4"
          placeholder="Анги тайлбар оруулна уу"
        ></textarea>
      </div>

      <!-- 6) Video File Input -->
      <div>
        <label class="block text-gray-700 mb-1" for="video">Бичлэг файл</label>
        <input
          id="video"
          name="video"
          @change="onEpisodeFileSelected"
          ref="fileVideoRef"
          type="file"
          accept="video/*"
          class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Бичлэг оруулна уу"
        />
      </div>

      <!-- TUS Upload Progress Bar -->
      <div v-if="uploadProgress > 0 && uploadProgress < 100" class="mt-4">
        <p class="mb-1">
          Бичлэгийг оруулж байна:
          <strong>{{ uploadProgress.toFixed(2) }}%</strong>
        </p>
        <p class="mb-2">
          Үлдсэн хугацаа:
          <strong>{{ formattedTimeRemaining }}</strong>
        </p>
        <div class="w-full bg-gray-200 rounded-full h-4">
          <div class="bg-blue-600 h-4 rounded-full transition-all duration-300" :style="{ width: uploadProgress + '%' }"></div>
        </div>
      </div>
      <div v-else-if="uploadProgress >= 100" class="mt-4">
        <p class="text-green-600 font-semibold">Бичлэг амжилттай илгээгдлээ!</p>
      </div>

      <!-- 7) Submit Button -->
      <div>
        <button type="submit" :disabled="isLoading" class="w-[250px] bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-lg transition duration-200 disabled:opacity-50">
          {{ isLoading ? "Анги нэмэж байна..." : "Анги нэмэх" }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch } from "vue";
import { useStore } from "vuex";
import * as tus from "tus-js-client"; // TUS library

export default {
  name: "CourseUploadForm", // Or "EpisodeUploadForm"
  setup() {
    const store = useStore();

    // Refs
    const fileVideoRef = ref(null);
    const selectedEpisodeFile = ref(null);

    // TUS progress
    const uploadProgress = ref(0);
    const timeRemaining = ref(0);
    const startTime = ref(null);

    // Computed from store
    const isLoading = computed(() => store.state.vod.isLoading);
    const seriesList = computed(() => store.state.vod.seriesList);
    const seasons = computed(() => store.state.vod.seasons);
    const seriesEpisodeForm = computed(() => store.state.vod.seriesEpisodeForm);

    /**
     * Format the remaining time in seconds into a more friendly string
     */
    const formattedTimeRemaining = computed(() => {
      let t = timeRemaining.value;
      if (t < 0) t = 0;

      if (t < 60) {
        return `${t.toFixed(0)}сек`;
      } else if (t < 3600) {
        const minutes = Math.floor(t / 60);
        const seconds = Math.floor(t % 60);
        return `${minutes}мин ${seconds}сек`;
      } else if (t < 86400) {
        const hours = Math.floor(t / 3600);
        const remainder = t % 3600;
        const minutes = Math.floor(remainder / 60);
        return `${hours}цаг ${minutes}мин`;
      } else {
        const days = Math.floor(t / 86400);
        const remainder = t % 86400;
        const hours = Math.floor(remainder / 3600);
        return `${days}өдөр ${hours}цаг`;
      }
    });

    /**
     * When user selects the video file
     */
    function onEpisodeFileSelected(e) {
      selectedEpisodeFile.value = e.target.files[0] || null;
      // Reset progress each time a new file is selected
      uploadProgress.value = 0;
      timeRemaining.value = 0;
    }

    /**
     * TUS-based upload
     */
    function startTusUpload() {
      return new Promise((resolve, reject) => {
        if (!selectedEpisodeFile.value) {
          return reject(new Error("No episode file selected!"));
        }

        startTime.value = Date.now();

        const upload = new tus.Upload(selectedEpisodeFile.value, {
          endpoint: "http://localhost:3002/tus-video/series",
          // Or your actual endpoint e.g. "/tus-video/episode"
          retryDelays: [0, 3000, 5000, 10000, 20000],
          metadata: {
            filename: selectedEpisodeFile.value.name,
            filetype: selectedEpisodeFile.value.type,
          },
          onError: (error) => {
            console.error("Episode upload error:", error);
            reject(error);
          },
          onProgress: (bytesUploaded, bytesTotal) => {
            const percentage = (bytesUploaded / bytesTotal) * 100;
            uploadProgress.value = percentage;

            const currentTime = Date.now();
            const timeElapsed = (currentTime - startTime.value) / 1000;
            const uploadSpeed = bytesUploaded / timeElapsed;
            const bytesRemaining = bytesTotal - bytesUploaded;
            const estimatedTime = uploadSpeed ? bytesRemaining / uploadSpeed : 0;
            timeRemaining.value = estimatedTime;
          },
          onSuccess: () => {
            uploadProgress.value = 100;
            timeRemaining.value = 0;
            // Save the TUS URL into your store form
            store.commit("vod/setSeriesEpisodeForm", {
              key: "video",
              value: upload.url,
            });
            resolve(upload.url);
          },
        });

        // If a partial upload was found, resume
        upload.findPreviousUploads().then((previousUploads) => {
          if (previousUploads.length) {
            upload.resumeFromPreviousUpload(previousUploads[0]);
          }
          upload.start();
        });
      });
    }

    /**
     * On form changes, update store
     */
    const handleSeriesEpisodeForm = (e) => {
      store.commit("vod/setSeriesEpisodeForm", {
        key: e.target.name,
        value: e.target.value,
      });
    };

    /**
     * Submit Episode:
     *  1) TUS upload to get final URL
     *  2) Once done, dispatch createEpisode
     */
    const submitEpisode = async () => {
      try {
        // 1) Start TUS upload (unless you've already stored the file or not needed)
        if (selectedEpisodeFile.value) {
          await startTusUpload();
        }

        // 2) Dispatch createEpisode in Vuex
        await store.dispatch("vod/createEpisode");

        // 3) Reset file input
        if (fileVideoRef.value) {
          fileVideoRef.value.value = "";
        }
        selectedEpisodeFile.value = null;
        uploadProgress.value = 0;
        timeRemaining.value = 0;

        alert("Анги амжилттай нэмэгдлээ!"); // Encouraging success message
      } catch (err) {
        console.error("submitEpisode error:", err);
        alert("Бичлэг илгээх үед алдаа гарлаа: " + err.message);
      }
    };

    /**
     * Watch seriesId => fetch seasons for that series
     */
    watch(
      () => seriesEpisodeForm.value.seriesId,
      (seriesId) => {
        if (seriesId) {
          store.dispatch("vod/fetchSeasonsBySeriesId", seriesId);
        }
      }
    );

    onMounted(() => {
      store.dispatch("vod/fetchSeriesList");
    });

    return {
      // refs
      fileVideoRef,
      selectedEpisodeFile,

      // store-based
      isLoading,
      seriesList,
      seasons,
      seriesEpisodeForm,

      // methods
      handleSeriesEpisodeForm,
      onEpisodeFileSelected,
      submitEpisode,
      startTusUpload,

      // TUS progress
      uploadProgress,
      timeRemaining,
      formattedTimeRemaining,
    };
  },
};
</script>

<style scoped>
/* Optional: your custom styles here */
</style>
