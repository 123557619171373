<template>
  <client-container>
    <div class="max-w-4xl mx-auto p-4">
      <!-- Only show if we have payment options -->
      <div v-if="paymentOptions.length">
        <h2 class="text-2xl font-bold mb-8 text-center">Төлбөр төлөх сонголтууд</h2>

        <!-- Responsive grid for payment options -->
        <div class="grid grid-cols-3 gap-6">
          <div
            v-for="(option, index) in paymentOptions"
            :key="index"
            class="flex flex-col items-center p-4 bg-gray-50 rounded-lg shadow cursor-pointer hover:shadow-lg transition-shadow duration-300 ease-in-out"
            @click="handlePaymentOptionClick(option.link)"
          >
            <img :src="option.logo" alt="Payment Option" class="w-16 h-16 mb-3 rounded-full bg-white object-contain" />
            <p class="text-sm md:text-base font-semibold text-center text-gray-700">
              {{ option.description }}
            </p>
          </div>
        </div>
      </div>

      <!-- Payment success message -->
      <div v-if="paymentStatus === 'paid'" class="mt-8 p-4 bg-green-100 border border-green-300 text-green-700 rounded-lg shadow">
        <div class="flex items-center space-x-2 mb-2">
          <!-- Example using Font Awesome icon, but you can replace with your own -->
          <font-awesome-icon class="w-6 h-6 text-green-500" :icon="['far', 'circle-check']" />
          <h2 class="text-lg font-semibold">Төлбөр амжилттай хийгдлээ</h2>
        </div>
        <p>Таны төлбөрийг амжилттай баталгаажууллаа. MNBApp-аар дамжуулан дуртай контентоо хүлээн авч үзээрэй.</p>

        <!-- Dynamic button to open the correct app store -->
        <button type="button" class="mt-4 inline-block px-6 py-2 bg-blue-600 text-white font-semibold rounded-md shadow hover:bg-blue-700 hover:shadow-lg transition duration-200">
          <a :href="appUrl" target="_blank" rel="noopener noreferrer"> MNBApp </a>
        </button>
      </div>
    </div>
  </client-container>
</template>

<script setup>
import { useStore } from "vuex";
import { computed, onMounted, onUnmounted, watch, ref } from "vue";
import { useRouter } from "vue-router";

function detectDeviceType() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "Other";
}

const router = useRouter();
const store = useStore();

const paymentOptions = computed(() => store.state.client.paymentOptions);
const paymentStatus = computed(() => store.state.client.paymentStatus);
const subscriptionStatus = computed(() => store.state.client.subscription || null);

const appUrls = {
  playStore: "https://play.google.com/store/apps/details?id=mn.mnb.mnbapp&hl=en_US",
  ios: "https://apps.apple.com/us/app/mnb-app/id1506602348",
};

const deviceType = ref("Other");
const appUrl = computed(() => {
  if (deviceType.value === "Android") {
    return appUrls.playStore;
  } else if (deviceType.value === "iOS") {
    return appUrls.ios;
  } else {
    return appUrls.playStore;
  }
});

const maxChecks = 4;
let checkCount = 0;
let intervalId = null;

const handleVisibilityChange = () => {
  if (document.visibilityState === "visible" && paymentStatus.value !== "paid") {
    clearCheckInterval();
    checkCount = 0;
    intervalId = setInterval(() => {
      checkCount++;
      store.dispatch("client/checkPaymentStatus");

      if (checkCount >= maxChecks) {
        clearCheckInterval();
      }
    }, 3000);
  } else {
    clearCheckInterval();
  }
};

/**
 * Clear the polling interval
 */
const clearCheckInterval = () => {
  if (intervalId) {
    clearInterval(intervalId);
    intervalId = null;
  }
};

/**
 * Open payment link in a new tab
 * @param {string} link
 */
const handlePaymentOptionClick = (link) => {
  window.open(link, "_blank");
};

/**
 * On component mount, start listening to visibility change
 */
onMounted(async () => {
  await store.dispatch("client/checkUserSubscription");
  if (subscriptionStatus.value?.status === "active") {
    router.push("/profile");
  }

  if (!paymentOptions.value.length && !paymentStatus.value && subscriptionStatus.value?.status !== "active") {
    router.push("/signup/planChoose");
  }

  deviceType.value = detectDeviceType();
  document.addEventListener("visibilitychange", handleVisibilityChange);
});

/**
 * On component unmount, clean up
 */
onUnmounted(() => {
  document.removeEventListener("visibilitychange", handleVisibilityChange);
  clearCheckInterval();
});

/**
 * Watch for payment status changes
 */
watch(paymentStatus, (newStatus, oldStatus) => {
  if (newStatus === "paid") {
    clearCheckInterval();

    store.commit("client/SET_PAYMENT_OPTIONS", []);
  }

  if (oldStatus === "paid" && newStatus === null) {
    router.push("/profile");
  }
});
</script>

<style scoped></style>
