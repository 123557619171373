<template>
  <admin-container>
    <div class="flex space-x-4 border-b mb-4">
      <button v-for="component in components" :key="component.name" :class="tabClass(component.name)" @click="setActiveTab(component.name)" :aria-selected="activeTab === component.name">
        <span class="text-blue-500">
          {{ component.title }}
        </span>
      </button>
    </div>

    <div class="max-w-[576px] text-sm bg-white px-2">
      <component :is="currentComponent" />
    </div>
  </admin-container>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import AdminContainer from "./AdminContainer.vue";

import VideoUploadForm from "@/components/forms/VideoUploadForm.vue";
import SeriesUploadForm from "@/components/forms/SeriesUploadForm.vue";
import CategoryUploadForm from "@/components/forms/CategoryUploadForm.vue";
import SeriesEpisodeUpload from "@/components/forms/SeriesEpisodeUpload.vue";
import SeriesSeasonUploadForm from "@/components/forms/SeriesSeasonUploadForm.vue";

import TusUploadForm from "@/components/forms/TusUploadForm.vue";
import TusSeriesUploadForm from "@/components/forms/TusSeriesUploadForm.vue";
export default {
  name: "AdminVideoUpload",
  components: {
    AdminContainer,
  },
  setup() {
    const store = useStore();

    const activeTab = ref("tus");

    onMounted(() => {
      store.dispatch("vod/fetchCategories");
    });

    const isLoading = computed(() => store.state.admin.isLoading);

    const tabClass = (tab) =>
      `py-2 px-4 rounded-t-lg text-sm font-medium focus:outline-none ${
        activeTab.value === tab ? "text-blue-600 border-b-2 border-blue-600" : "text-gray-500 hover:text-gray-700 border-b-2 border-transparent"
      }`;

    const components = [
      {
        title: "Бичлэг нэмэх",
        name: "video",
        component: VideoUploadForm,
      },
      {
        title: "Цуврал анги нэмэх",
        name: "episode",
        component: SeriesEpisodeUpload,
      },
      {
        title: "Цуврал бүлэг нэмэх",
        name: "season",
        component: SeriesSeasonUploadForm,
      },
      {
        title: "Цуврал нэмэх",
        name: "series",
        component: SeriesUploadForm,
      },
      {
        title: "Төрөл нэмэх",
        name: "category",
        component: CategoryUploadForm,
      },
      {
        title: "Testing single video",
        name: "tus",
        component: TusUploadForm,
      },
      {
        title: "Testing series",
        name: "tus-series",
        component: TusSeriesUploadForm,
      },
    ];

    const currentComponent = computed(() => {
      const matchedComponent = components.find((comp) => comp.name === activeTab.value);
      if (!matchedComponent) {
        console.warn(`Component not found for tab: ${activeTab.value}`);
        return null;
      }
      return matchedComponent.component;
    });

    const setActiveTab = (tabName) => {
      activeTab.value = tabName;
    };

    return {
      activeTab,
      currentComponent,
      isLoading,
      tabClass,
      components,
      setActiveTab,
    };
  },
};
</script>

<style scoped>
button {
  transition: color 0.3s, border-color 0.3s;
}
</style>
