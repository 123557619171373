import axios from "axios";

let clientBaseUrl;
if (process.env.NODE_ENV === "production") {
  clientBaseUrl = "https://app.mnb.mn/api";
} else {
  clientBaseUrl = "http://192.168.1.3:3002/api";
}

export const clientInstance = axios.create({
  baseURL: clientBaseUrl,
  withCredentials: true,
});
