<template>
  <client-container>
    <!-- Main Content -->
    <div class="flex justify-center py-12 px-6">
      <div class="max-w-md w-full text-black">
        <!-- Title -->
        <h1 class="text-2xl font-bold mb-8 text-center">Багц сонгох</h1>

        <!-- Plans -->
        <div class="flex flex-col gap-4">
          <!-- Loop through planOptions from the store -->
          <div
            v-for="plan in planOptions"
            :key="plan.months"
            @click="selectPlan(plan)"
            class="relative cursor-pointer p-6 rounded-lg shadow-md transition-shadow"
            :class="{
              'bg-gradient-to-r from-[#340474] to-[#74030E] text-white border-transparent hover:shadow-lg': selectedPlan?.months === plan.months,
              'bg-white text-gray-800 border border-gray-300 hover:shadow-md': selectedPlan?.months !== plan.months,
            }"
          >
            <div class="flex items-center justify-between">
              <div>
                <p
                  class="text-lg font-semibold mb-1"
                  :class="{
                    'text-white': selectedPlan?.months === plan.months,
                    'text-gray-800': selectedPlan?.months !== plan.months,
                  }"
                >
                  {{ plan.label }}
                </p>
                <p
                  class=""
                  :class="{
                    'text-gray-200': selectedPlan?.months === plan.months,
                    'text-gray-600': selectedPlan?.months !== plan.months,
                  }"
                >
                  Нийт:
                  <span
                    class="font-medium"
                    :class="{
                      'text-white': selectedPlan?.months === plan.months,
                      'text-gray-800': selectedPlan?.months !== plan.months,
                    }"
                  >
                    {{ plan.price.toLocaleString() }}₮
                  </span>
                  <span
                    class="text-sm ml-1"
                    :class="{
                      'text-gray-300': selectedPlan?.months === plan.months,
                      'text-gray-500': selectedPlan?.months !== plan.months,
                    }"
                  >
                    ({{ plan.months }} сар)
                  </span>
                </p>
              </div>

              <!-- Example badge -->
              <div v-if="plan.months === 6" class="absolute top-2 right-2 bg-red-500 text-white text-xs px-2 py-1 rounded" :class="{ 'opacity-90': selectedPlan?.months === plan.months }">Онцлох</div>
            </div>
          </div>
        </div>

        <!-- Continue Button -->
        <button @click="goPayment" class="w-full bg-[#F60002] text-white font-bold py-3 rounded mt-8 hover:bg-red-600 transition-colors text-lg">Төлбөр төлөх</button>
      </div>
    </div>
  </client-container>
</template>

<script setup>
import { computed, ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const store = useStore();
const router = useRouter();

const selectedPlan = ref(null);

const planOptions = computed(() => store.state.client.planOptions);

onMounted(() => {
  if (store.state.client.selectedPlan) {
    selectedPlan.value = store.state.client.selectedPlan;
  }
});

function selectPlan(plan) {
  selectedPlan.value = plan;
  store.dispatch("client/choosePlan", plan);
}

function goPayment() {
  if (!selectedPlan.value) {
    alert("Та төлөвлөгөө сонгоно уу.");
    return;
  }
  store.dispatch("client/payment");
  router.push("/signup/payment");
}
</script>

<style scoped></style>
