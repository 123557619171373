<template>
  <div class="min-h-screen">
    <header class="p-4 border-b flex items-center">
      <div class="w-24">
        <img src="../../assets/logo1.png" alt="Манай Лого" />
      </div>
    </header>
    <div>
      <slot></slot>
    </div>
  </div>
</template>

<script setup></script>
