<template>
  <client-container>
    <!-- Delete Confirmation Modal -->
    <div v-if="showDeleteModal" class="fixed inset-0 bg-black/30 backdrop-blur-sm flex items-center justify-center z-50 p-4">
      <div class="bg-white rounded-2xl max-w-md w-full p-6 space-y-6 shadow-xl">
        <div class="flex items-center justify-between">
          <h3 class="text-2xl font-bold text-gray-900">Бүртгэл устгах</h3>
          <button @click="showDeleteModal = false" class="text-gray-400 hover:text-gray-600 transition-colors">
            <font-awesome-icon icon="fa-solid fa-xmark" class="text-xl" />
          </button>
        </div>

        <p class="text-gray-600 leading-relaxed">
          Та өөрийн бүртгэлийг бүрмөсөн устгахдаа итгэлтэй байна уу?<br />
          <span class="font-medium text-red-500">Энэ үйлдлийг буцаах боломжгүй!</span>
        </p>

        <div class="flex gap-3 justify-end">
          <button @click="showDeleteModal = false" class="px-5 py-2.5 text-gray-600 hover:bg-gray-50 rounded-xl transition-colors font-medium" :disabled="isDeleting">Цуцлах</button>
          <button @click="handleDeleteAccount" class="px-5 py-2.5 bg-red-500 text-white rounded-xl hover:bg-red-600 transition-colors flex items-center font-medium" :disabled="isDeleting">
            <font-awesome-icon v-if="!isDeleting" icon="fa-solid fa-trash-can" class="mr-2" />
            <span v-if="isDeleting">Устгаж байна...</span>
            <span v-else>Устгах</span>
          </button>
        </div>
      </div>
    </div>

    <!-- Main Content -->
    <div class="min-h-screen px-4 py-8 sm:py-12">
      <div class="max-w-3xl mx-auto">
        <!-- Header Skeleton -->
        <div class="mb-8 text-center" v-if="isLoading">
          <div class="h-8 bg-gray-200 rounded-full w-48 mx-auto mb-2 animate-pulse"></div>
          <div class="h-4 bg-gray-200 rounded-full w-64 mx-auto animate-pulse"></div>
        </div>

        <!-- Loaded Header -->
        <div class="mb-8 text-center" v-else>
          <h1 class="text-3xl font-bold text-gray-900">Профайл</h1>
          <p class="text-gray-500 mt-2">Таны бүртгэлийн мэдээлэл</p>
        </div>

        <!-- Profile Card -->
        <div class="bg-white rounded-2xl p-6 shadow-sm hover:shadow-md transition-shadow border border-gray-100">
          <!-- Loading State -->
          <template v-if="isLoading">
            <!-- Subscription Section Skeleton -->
            <div class="space-y-6">
              <div class="pb-4 border-b border-gray-100">
                <div class="h-6 bg-gray-200 rounded-full w-32 animate-pulse"></div>
              </div>

              <div class="space-y-4">
                <div class="flex items-center p-3 bg-gray-50 rounded-lg">
                  <div class="w-5 h-5 mr-3 bg-gray-300 rounded-full animate-pulse"></div>
                  <div class="flex-1 space-y-2">
                    <div class="h-3 bg-gray-300 rounded w-1/4 animate-pulse"></div>
                    <div class="h-4 bg-gray-300 rounded w-3/4 animate-pulse"></div>
                  </div>
                </div>

                <div class="flex items-center p-3 bg-gray-50 rounded-lg">
                  <div class="w-5 h-5 mr-3 bg-gray-300 rounded-full animate-pulse"></div>
                  <div class="flex-1 space-y-2">
                    <div class="h-3 bg-gray-300 rounded w-1/4 animate-pulse"></div>
                    <div class="h-4 bg-gray-300 rounded w-3/4 animate-pulse"></div>
                  </div>
                </div>

                <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div class="p-3 bg-gray-50 rounded-lg space-y-2">
                    <div class="h-3 bg-gray-300 rounded w-1/4 animate-pulse"></div>
                    <div class="h-4 bg-gray-300 rounded w-3/4 animate-pulse"></div>
                  </div>
                  <div class="p-3 bg-gray-50 rounded-lg space-y-2">
                    <div class="h-3 bg-gray-300 rounded w-1/4 animate-pulse"></div>
                    <div class="h-4 bg-gray-300 rounded w-3/4 animate-pulse"></div>
                  </div>
                </div>
              </div>
            </div>

            <!-- User Info Skeleton -->
            <div class="mt-8 space-y-6">
              <div class="pb-4 border-b border-gray-100">
                <div class="h-6 bg-gray-300 rounded-full w-48 animate-pulse"></div>
              </div>
              <div class="flex items-center p-3 bg-gray-50 rounded-lg">
                <div class="w-5 h-5 mr-3 bg-gray-300 rounded-full animate-pulse"></div>
                <div class="flex-1 space-y-2">
                  <div class="h-3 bg-gray-300 rounded w-1/4 animate-pulse"></div>
                  <div class="h-4 bg-gray-300 rounded w-3/4 animate-pulse"></div>
                </div>
              </div>
            </div>

            <!-- Buttons Skeleton -->
            <div class="mt-8 space-y-4">
              <div class="h-12 bg-gray-200 rounded-xl animate-pulse"></div>
              <div class="h-12 bg-gray-200 rounded-xl animate-pulse"></div>
            </div>
          </template>

          <!-- Loaded State -->
          <template v-else>
            <!-- Subscription Section -->
            <div class="space-y-6">
              <div class="pb-4 border-b border-gray-100">
                <h2 class="text-lg font-semibold text-gray-900">Багцын мэдээлэл</h2>
              </div>

              <div class="space-y-4">
                <div class="flex items-center p-3 bg-gray-50 rounded-lg">
                  <font-awesome-icon icon="fa-solid fa-clock" class="w-5 h-5 mr-3 text-gray-600" />
                  <div class="flex-1">
                    <span class="text-sm text-gray-500">Багц</span>
                    <p class="font-medium text-gray-900">{{ subscription?.subscription_months }} сар</p>
                  </div>
                </div>

                <div class="flex items-center p-3 bg-gray-50 rounded-lg">
                  <font-awesome-icon
                    :icon="subscription?.status === 'active' ? 'fa-solid fa-circle-check' : 'fa-solid fa-circle-xmark'"
                    class="w-5 h-5 mr-3"
                    :class="subscription?.status === 'active' ? 'text-green-500' : 'text-red-500'"
                  />
                  <div class="flex-1">
                    <span class="text-sm text-gray-500">Төлөв</span>
                    <p class="font-medium text-gray-900 capitalize">
                      {{ subscription?.status === "active" ? "Идэвхтэй" : "Идэвхгүй" }}
                    </p>
                  </div>
                </div>

                <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div class="p-3 bg-gray-50 rounded-lg">
                    <span class="text-sm text-gray-500">Эхэлсэн огноо</span>
                    <p class="font-medium text-gray-900">{{ subscription?.start_date }}</p>
                  </div>
                  <div class="p-3 bg-gray-50 rounded-lg">
                    <span class="text-sm text-gray-500">Дуусах огноо</span>
                    <p class="font-medium text-gray-900">{{ subscription?.end_date }}</p>
                  </div>
                </div>
              </div>
            </div>

            <!-- User Info Section -->
            <div class="mt-8 space-y-6">
              <div class="pb-4 border-b border-gray-100">
                <h2 class="text-lg font-semibold text-gray-900">Хэрэглэгчийн мэдээлэл</h2>
              </div>

              <div class="p-3 bg-gray-50 rounded-lg">
                <div class="flex items-center">
                  <font-awesome-icon icon="fa-solid fa-envelope" class="w-5 h-5 mr-3 text-gray-600" />
                  <div class="flex-1">
                    <span class="text-sm text-gray-500">И-Мэйл</span>
                    <p class="font-medium text-gray-900">{{ profile?.email }}</p>
                  </div>
                </div>
              </div>
            </div>

            <!-- Action Buttons -->
            <div class="mt-8 space-y-3">
              <!-- Subscription CTA Button -->
              <button
                v-if="subscription?.status !== 'active'"
                @click="router.push('/signup/planChoose')"
                class="w-full py-3 px-4 rounded-lg border border-green-500 text-green-600 hover:bg-green-50 transition-colors flex items-center justify-center space-x-2"
              >
                <font-awesome-icon icon="fa-solid fa-arrow-right" class="w-4 h-4" />
                <span class="font-medium">Багц сонгох</span>
              </button>

              <!-- Logout Button -->
              <button @click="logout" class="w-full py-3 px-4 rounded-lg border border-gray-300 text-gray-700 hover:bg-gray-50 transition-colors flex items-center justify-center space-x-2">
                <font-awesome-icon icon="fa-solid fa-right-from-bracket" class="w-4 h-4" />
                <span class="font-medium">Гарах</span>
              </button>

              <!-- Delete Account Button -->
              <button
                @click="showDeleteModal = true"
                class="w-full py-3 px-4 rounded-lg border border-red-100 text-red-500 hover:bg-red-50 transition-colors flex items-center justify-center space-x-2"
              >
                <font-awesome-icon icon="fa-solid fa-trash-can" class="w-4 h-4" />
                <span class="font-medium">Бүртгэл устгах</span>
              </button>
            </div>
          </template>
        </div>
      </div>
    </div>
  </client-container>
</template>

<script setup>
/* eslint-disable */
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const store = useStore();
const router = useRouter();

// States
const showDeleteModal = ref(false);
const isDeleting = ref(false);

// Computed
const isLoading = computed(() => store.state.client.isLoading);
const subscription = computed(() => store.state.client.subscription);
const profile = computed(() => store.state.client.profile || {});

// Methods
const logout = async () => {
  await store.dispatch("client/logout");
};

const handleDeleteAccount = async () => {
  try {
    isDeleting.value = true;
    await store.dispatch("client/deleteAccount");
    router.push("/signup");
  } catch (error) {
    console.error("Account deletion failed:", error);
    alert("Бүртгэл устгахад алдаа гарлаа. Дахин оролдоно уу.");
  } finally {
    isDeleting.value = false;
    showDeleteModal.value = false;
  }
};

// Lifecycle
onMounted(() => {
  store.dispatch("client/checkUserSubscription");
  store.dispatch("client/fetchProfileInfo");
});
</script>

<style scoped>
.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.2s ease;
}
.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}
</style>
